/* .circle {
  width: 180px;
  height: 160px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .ring {
  width: 130px;
  height: 130px;
  border: 15px solid;
  border-radius: 50%;
} */
.triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid #8F68A7 ;
}
