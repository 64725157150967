.image-wrapper {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease;
}

.image {
  max-width: 100%;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-wrapper.enlarged {
  transform: scale(1.1);
}

.image-wrapper.enlarged .text-overlay {
  opacity: 1;
}

 
