@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  direction: ltr;
  background-image: url("../src/Images/Mask\ group.png ");
  background-repeat: repeat-y;
  min-height: 100vh;
  background-position: 0 bottom 0 top;
  background-size: contain;
}
ul li a.active {
  color: rgb(238, 177, 11) !important;
  font-size: 25px;
}

/* ul li a:last-child{
  background:blue;
} */
/* ul li a:hover{
  color : blue ;
} */

.loadingPro div {
  background-color: #dadada;
  border-radius: 5px;
  animation: load 1.1s infinite;
}

.bg {
  background-image: url("../src/Images/Mask\ group.png ");
  background-position: 0 bottom 0 top;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 120vh;
}

.scrollHide {
  scrollbar-width: none !important;
}
